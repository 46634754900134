@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

@import "utilities/_variables";
@import "utilities/_placeholders";
@import "utilities/_mixins";
@import "utilities/_helpers";
@import "../../node_modules/sass-mq/mq";

@import "base/_reset";
@import "base/_generic";
@import "base/_typography";

// @import "elements/_content";
// @import "elements/_buttons";
// @import "elements/_figures";
// @import "elements/_forms";
// @import "elements/_icons";
// @import "elements/_tables";

// @import "components/_menu";
// @import "components/_breadcrumb";
// @import "components/_pagination";

// @import "layout/_header";
// @import "layout/_footer";
@import "layout/_site";

// @media print { @import "media/print"; }
